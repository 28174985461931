<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $t('create') }} {{ $tc('addOn', 1) }}</span>
    </div>
    <el-form
      ref="form"
      v-loading="loading.main"
      :rules="form.rules"
      :model="form.model"
      label-position="top"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$tc('name', 1)" prop="addOnName">
            <el-input v-model="form.model.addOnName" :maxlength="64"></el-input>
          </el-form-item>
          <el-form-item
            :label="$tc('collectRecurrence', 1)"
            prop="addOnBillRecurrence"
          >
            <el-select v-model="form.model.addOnBillRecurrence">
              <el-option value="Initial" label="Cobro inicial"></el-option>
              <el-option
                value="ByPlan"
                label="De acuerdo a recurrencia del plan"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$tc('description', 1)">
            <el-input
              v-model="form.model.addOnDescription"
              type="textarea"
              :rows="2"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$tc('icon', 1)">
            <el-input v-model="form.model.addOnIcon" type="text">
              <template slot="append">
                <i
                  v-if="form.model.addOnIcon"
                  class="fad"
                  :class="form.model.addOnIcon"
                  size="2x"
                >
                </i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$tc('service', 1)" prop="serviceId">
            <select-service v-model="form.model.serviceId"></select-service>
          </el-form-item>
          <el-form-item>
            <el-button
              size="mini"
              type="primary"
              :loading="loading.main"
              @click="onSave"
            >
              {{ $t('save') }}
            </el-button>
            <router-link
              class="el-button el-button--text el-button--small"
              to="/add-ons"
              tag="button"
            >
              {{ $t('cancel') }}
            </router-link>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$tc('plan', 1)" prop="plan">
            <select-searchable-service-plan-id
              v-model="form.model.planId"
              :service-id="form.model.serviceId"
            ></select-searchable-service-plan-id>
          </el-form-item>
          <el-form-item :label="$tc('price', 1)" prop="addOnPrice">
            <el-input
              v-model.number="form.model.addOnPrice"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('taxPercent', 1)">
            <el-input v-model.number="addOnTaxPercent" type="number">
              <template slot="prepend">
                {{ $tc('isIncluded', 1) }} &nbsp;<input
                  v-model="addOnTaxIsIncluded"
                  type="checkbox"
                />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$tc('isMandatory')">
            <el-switch v-model="form.model.addOnIsMandatory"> </el-switch>
          </el-form-item>
          <el-form-item :label="$tc('isEnabled', 1)">
            <el-switch v-model="form.model.addOnEnabled"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
import { i18n } from '../../i18n'
import formModel from './model'
import formRules from './rules'

export default {
  name: 'AddOnCreate',
  data() {
    return {
      loading: {
        main: false,
        form: false,
      },
      form: {
        model: { ...formModel() },
        rules: { ...formRules() },
      },
    }
  },
  computed: {
    addOnTaxIsIncluded: {
      get: _this =>
        _this.form.model.addOnTaxPercentIncluded !== '' &&
        _this.form.model.addOnTaxPercentIncluded !== null,
      set(value) {
        const _this = this
        if (value) {
          _this.form.model.addOnTaxPercentIncluded = _this.addOnTaxPercent || 0
          _this.form.model.addOnTaxPercentIncrease = ''
        } else {
          _this.form.model.addOnTaxPercentIncrease = _this.addOnTaxPercent || 0
          _this.form.model.addOnTaxPercentIncluded = ''
        }
      },
    },
    addOnTaxPercent: {
      get: _this =>
        _this.addOnTaxIsIncluded
          ? _this.form.model.addOnTaxPercentIncluded
          : _this.form.model.addOnTaxPercentIncrease,
      set(value) {
        const _this = this
        if (_this.addOnTaxIsIncluded) {
          _this.form.model.addOnTaxPercentIncluded = value
          _this.form.model.addOnTaxPercentIncrease = ''
        } else {
          _this.form.model.addOnTaxPercentIncrease = value
          _this.form.model.addOnTaxPercentIncluded = ''
        }
      },
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      const _this = this
      _this.loading.main = true
      _this.$http
        .get(`add-ons/${_this.$route.params.id}`)
        .then(response => (_this.form.model = { ...response.body }))
        .finally(_ => (_this.loading.main = false))
    },
    onSave() {
      const _this = this
      _this.$refs.form.validate(valid => {
        if (valid) {
          _this.loading.main = true
          _this.$http
            .put(`add-ons/${_this.$route.params.id}`, _this.form.model)
            .then(response => {
              _this.$message({
                type: 'success',
                message: i18n.t('processSuccess'),
              })
            })
            .finally(_ => (_this.loading.main = false))
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')

          return false
        }
      })
    },
  },
}
</script>
